































































import {Component} from "vue-property-decorator";
import AbpBase from "@/lib/abpbase";
import ClientJob from "@/store/entities/jobcontext/clientjob";
import JobDocumentClient from "@/views/setting/jobcontext/job/job-document-client.vue";
import JobClientFormData from "@/views/setting/jobcontext/job/job-client-form-data.vue";
import NotifyJobOwnerForm from "@/views/setting/jobcontext/job/notify-owner-form.vue";

@Component({
  components: {JobDocumentClient, JobClientFormData, NotifyJobOwnerForm}
})
export default class JobClientForm extends AbpBase {
  private jobId: number;
  private job: ClientJob = new ClientJob();
  private documents: any;
  private nodes: any;
  private notifyOwnerShow: boolean = false;

  async mounted() {
    this.job = await this.$store.dispatch('job/getClientView', this.jobId);
    await this.getDocuments(this.jobId);
    await this.getNodes(this.jobId);
    await this.getConsolidationJobs();
  }

  notify() {
    this.notifyOwnerShow = true;
  }

  async getDocuments(jobId: number) {
    this.documents = await this.$store.dispatch('job/getClientJobDocuments', jobId);
  }

  async created() {
    this.jobId = parseInt(this.$route.params["jobId"]);
  }

  async getNodes(jobId: number) {
    this.nodes = await this.$store.dispatch('job/getNodes', jobId);
  }

  async getConsolidationJobs() {
    await this.$store.dispatch('job/getConsolidationJobs', this.job.consolidationNumber);
  }

  async changeRow(currentRow) {
    await this.$router.push({name: 'job-client-form', params: {jobId: currentRow.id.toString()}});
    this.$router.go(0);
  }

  data() {
    return {
      documents: [],
      nodes: []
    }
  };

  get list() {
    let index = this.$store.state.job.consolidationJobs.findIndex(x => this.job.id === x.id);
    if (index !== -1) {
      this.$store.state.job.consolidationJobs[index]._highlight = true;
    }
    return this.$store.state.job.consolidationJobs;
  }

  get containersList() {
    return this.job.containers;
  }


  columns = [{
    title: this.L('PO'),
    key: 'po'
  }, {
    title: this.L('Est. Freight Cost'),
    key: 'estFreightCost'
  }, {
    title: this.L('ACT FREIGHT COST'),
    key: 'finalFreightCost'
  }, {
    title: this.L('Job#'),
    key: 'number'
  }, {
    title: this.L('Cons#'),
    key: 'consolidationNumber'
  }]

  containerColumns = [{
    title: this.L('CONT #'),
    key: 'containerNumber'
  }, {
    title: this.L('SEAL #'),
    key: 'sealNumber'
  }, {
    title: this.L('CONTAINER SIZE'),
    key: 'containerSize'
  }]
}

